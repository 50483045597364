import { useState, useEffect } from "react";
import { useLocationContext } from "../contexts/LocationContext";
import Header from "../components/Header";
import PlantsMenu from "../components/PlantsMenu";
import PlantTile from "../components/PlantTile";
import Pagination from "../components/Pagination";

const PlantsPage = ({ path }) => {
  const { location } = useLocationContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [plants, setPlants] = useState();
  const [recordsDescription, setRecordsDescription] = useState("");

  const pageFragment = `?page=${currentPage}&pageSize=20`;
  const baseUrl = `https://plantea.aladlabs.net/api/v1/species/${path}/`;
  const initialUrl = baseUrl + location + pageFragment;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();

      setTotalPages(json.metadata.totalPages);
      setPlants(json.data);
      setTotalRecords(json.metadata.totalRecords);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    // Update recordsDescription directly here
    if (path === "/active") {
      if (totalRecords > 0) {
        setRecordsDescription(`${totalRecords} active plants present.`);
      } else {
        setRecordsDescription(
          "0 plants active in your location at the present time"
        );
      }
    } else if (path === "/blossoming") {
      if (totalRecords > 0) {
        setRecordsDescription(`${totalRecords} blooming plants present.`);
      } else {
        setRecordsDescription(
          "0 plants blooming in your location at the present time"
        );
      }
    } else if (path === "/harvesting") {
      if (totalRecords > 0) {
        setRecordsDescription(
          `${totalRecords} plants ready for harvesting present.`
        );
      } else {
        setRecordsDescription(
          "0 plants ready to harvest in your location at the present time"
        );
      }
    } else if (path === "/planting") {
      if (totalRecords > 0) {
        setRecordsDescription(
          `${totalRecords} plants suitable for planting present.`
        );
      } else {
        setRecordsDescription(
          "0 plants suitable for planting in your location at the present time"
        );
      }
    } else if (path === "/sow-inside") {
      if (totalRecords > 0) {
        setRecordsDescription(
          `${totalRecords} plants suitable for indoor sowing present.`
        );
      } else {
        setRecordsDescription(
          "0 plants suitable for indoor sowing in your location at the present time"
        );
      }
    } else if (path === "/sow-outside") {
      if (totalRecords > 0) {
        setRecordsDescription(
          `${totalRecords} plants suitable for outdoor sowing present.`
        );
      } else {
        setRecordsDescription(
          "0 plants suitable for outdoor sowing in your location at the present time"
        );
      }
    }
  }, [path, totalRecords]);

  useEffect(() => {
    setCurrentPage(1);
  }, [path]);

  useEffect(() => {
    setApiUrl(initialUrl);
  }, [location, currentPage, path]);

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/plants' title='PLANTS' loc={true} />
      <PlantsMenu />
      <div className={"content-container"}>
        <div className='records-info-container'>
          {plants ? recordsDescription : ""}
        </div>
        {plants && totalPages > 1 ? (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        ) : (
          <div className='pagination-placeholder'></div>
        )}
        <div className='tile-grid-container tile-grid'>
          {plants
            ? plants.map((plant) => {
                return (
                  <PlantTile
                    plant={plant}
                    id={plant.subspecieId}
                    key={plant.scientificName}
                  />
                );
              })
            : "loading..."}
        </div>
        {plants && totalPages > 1 ? (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        ) : (
          <div className='pagination-placeholder'></div>
        )}
      </div>
    </div>
  );
};

export default PlantsPage;
