const CultivarProducer = ({ cultivar }) => {
  return (
    <div className={"section"}>
      <h1 className='section-title green'>
        Exchange seeds with these breeders:
      </h1>
      <table className='producer'>
        <tbody>
          <tr>
            <td>Cultivar:</td>
            <td>
              <a
                href={cultivar.cultivarUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {cultivar.cultivarName}
              </a>
            </td>
          </tr>
          <tr>
            <td>Seller:</td>
            <td>{cultivar.producerName}</td>
          </tr>
          {/*<tr>*/}
          {/*    <td>Code:</td>*/}
          {/*    <td>{cultivar.sku}</td>*/}
          {/*</tr>*/}
        </tbody>
      </table>
    </div>
  );
};

export default CultivarProducer;
