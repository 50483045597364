import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Accordion from "../components/Accordion";
import PlantTaxonomy from "../components/PlantTaxonomy";
import PlantHero from "../components/PlantHero";
import PlantIdentity from "../components/PlantIdentity";
import PlantEdibleUses from "../components/PlantEdibleUses";
import PlantMedicalUses from "../components/PlantMedicalUses";
import PlantRecipes from "../components/PlantRecipes";
import PlantEffects from "../components/PlantEffects";
import PlantCultivars from "../components/PlantCultivars";
import { pickRandomAmount } from "../utils/pickRandomAmount.js";

const PlantPage = () => {
  const { id } = useParams();

  const [plant, setPlant] = useState();
  const [cultivars, setCultivars] = useState();
  const [wpArticle, setWpArticle] = useState();
  const [effects, setEffects] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/species/";
  const initialUrl = baseUrl + id;

  let wpUrl = "https://en.wikipedia.org/w/api.php";

  const getPlantData = async () => {
    try {
      const response = await fetch(initialUrl);
      const json = await response.json();

      setPlant(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCultivarsData = async () => {
    try {
      const response = await fetch(initialUrl + "/cultivars");
      const json = await response.json();

      setCultivars(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getwikiData = async () => {
    try {
      const response = await fetch(wpUrl);
      const json = await response.json();

      setWpArticle(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPlantData();
    getCultivarsData();
  }, [initialUrl]);

  useEffect(() => {
    try {
      if (plant?.effects?.length) {
        if (plant.effects.length <= 7) {
          setEffects(pickRandomAmount(plant.effects, plant.effects.length));
        } else {
          setEffects(pickRandomAmount(plant.effects, 7));
        }
      }

      if (plant?.scientificName) {
        const params = {
          action: "opensearch",
          search: plant.scientificName.split(" ").join("%20"),
          limit: "5",
          namespace: "0",
          format: "json",
        };

        wpUrl += "?origin=*";
        Object.keys(params).forEach(function (key) {
          wpUrl += "&" + key + "=" + params[key];
        });
        getwikiData();
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [plant]);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/plants' title='PLANTS' loc={true} />
      <div className={"content-container"}>
        <div className='page-hero-container section-flex-container'>
          {plant && <PlantTaxonomy plant={plant} />}
          {plant && <h1 className='specie-title'>{plant.commonName}</h1>}
          <PlantHero plant={plant} />
        </div>
        {plant && wpArticle && (
          <PlantIdentity plant={plant} wpArticle={wpArticle} />
        )}
        <div className='section-separator'></div>
        <Accordion
          label={"Edible uses"}
          content={plant && plant.edibleUses.replace(/\[[^\]]*\]/g, "")}
        />
        {plant && <PlantEdibleUses className={"desktop"} plant={plant} />}
        {plant && <PlantRecipes plant={plant} />}
        {plant && <PlantEffects plant={plant} effects={effects} />}
        <div className='section-separator'></div>
        <Accordion
          label={"Body effects"}
          content={plant && plant.medicinalUses.replace(/\[[^\]]*\]/g, "")}
        />
        {plant && <PlantMedicalUses className={"desktop"} plant={plant} />}

        {plant && cultivars && cultivars.length > 0 && (
          <div className={"section"}>
            <h1 className='section-title green'>
              You want to grow it yourself?
            </h1>
            <PlantCultivars plant={plant} cultivars={cultivars} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlantPage;
