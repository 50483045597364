import { NavLink } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";
import "../stylesheets/header.css";

const Header = ({ path, title, loc }) => {
  const { location, setLocation } = useLocationContext();
  const capitalizedLocation =
    location.charAt(0).toUpperCase() + location.slice(1);

  const handleChange = (event) => {
    // setLocation(event.target.value);
    if (event.target.value === "n") {
      localStorage.setItem("location", "n");
      setLocation("n");
    } else if (event.target.value === "s") {
      localStorage.setItem("location", "s");
      setLocation("s");
    }
  };

  return (
    <div className='header-container grid_2-14'>
      <div className='header-title'>
        <NavLink to={path} className='main-link'>
          {title}
        </NavLink>
        {loc === true && (
          <div className='location pad'>{capitalizedLocation}</div>
        )}
        {loc === true && (
          <div className='location mobile'>{capitalizedLocation}</div>
        )}
        {loc === true && (
          <select
            id='hemisphere-selector'
            className={"location desktop"}
            value={location}
            onChange={handleChange}
          >
            <option value='n'>N</option>
            <option value='s'>S</option>
          </select>
        )}
      </div>
      <div className='desktop'>
        <nav className='header-menu'>
          <NavLink className='1' to='/'>
            Now
          </NavLink>
          {" | "}
          <NavLink to='/plants'>Plants</NavLink>
          {" | "}
          <NavLink to='/recipes'>Recipes</NavLink>
          {" | "}
          <NavLink to='/conditions'>Effects</NavLink>
          {" | "}
          <NavLink to='/about'>About</NavLink>
        </nav>
      </div>
    </div>
  );
};

export default Header;
