import "../stylesheets/pagination.css";

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  // Works for 9 and 10 navigationElements
  const navigationElements = 9;
  // const center = navigationElements / 2;
  const center = Math.floor(navigationElements / 2);
  const lowerBoundary = center;
  const upperBoundary = totalPages - center;

  const createPages = () => {
    const pages = [];
    const maxPagesToShow = navigationElements - 2; // Adjust for potential "<" and ">"

    let start, end;

    if (totalPages > navigationElements && currentPage <= lowerBoundary) {
      start = 1; // Start after "1"
      end = Math.min(totalPages - 1, maxPagesToShow); // Leave room for ">"
    } else if (
      totalPages > navigationElements &&
      currentPage >= lowerBoundary &&
      currentPage < upperBoundary
    ) {
      start = currentPage - 2; // Start after "1"
      end = currentPage + 2; // Leave room for ">"
    } else if (
      totalPages > navigationElements &&
      currentPage >= upperBoundary
    ) {
      start = Math.min(totalPages - maxPagesToShow + 1, totalPages - 2); // Start after "1"
      end = totalPages; // Leave room for ">"
    } else {
      start = 1;
      end = totalPages;
    }

    // Add the range of page numbers
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className='pagination-links'>
      {totalPages > navigationElements && currentPage > center && (
        <div
          key={totalPages}
          className='page-link'
          onClick={() => {
            setCurrentPage(1);
          }}
        >
          1
        </div>
      )}
      {totalPages > navigationElements &&
        currentPage !== 1 &&
        currentPage > lowerBoundary && (
          <div
            key='prev'
            className='page-link'
            onClick={() => {
              setCurrentPage((curr) => curr - 1);
            }}
          >
            {"<"}
          </div>
        )}

      {createPages().map((page) => {
        return (
          <div
            key={page}
            className={`${
              page === currentPage ? "active-page-link" : "page-link"
            }`}
            id={page}
            value={page}
            onClick={() => {
              setCurrentPage(page);
            }}
          >
            {page}
          </div>
        );
      })}
      {totalPages > navigationElements &&
        totalPages !== currentPage &&
        currentPage < upperBoundary && (
          <div
            key='next'
            className='page-link'
            onClick={() => {
              setCurrentPage((curr) => curr + 1);
            }}
          >
            {">"}
          </div>
        )}
      {totalPages > navigationElements && currentPage < upperBoundary && (
        <>
          <div
            key={totalPages}
            className='page-link'
            onClick={() => {
              setCurrentPage(totalPages);
            }}
          >
            {totalPages}
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
