import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";
import "../stylesheets/sideMenu.css";

const SideMenu = () => {
  const { location, setLocation } = useLocationContext();
  const [activeButton, setActiveButton] = useState(
    `button${location.toUpperCase()}`
  );
  const btnOpen = useRef(null);
  const btnClose = useRef(null);

  const handleOpenClick = () => {
    let content = document.querySelector("#content-wrapper");
    let headernav = document.querySelector("#side-menu-links");
    let buttonOpen = document.querySelector("#side-menu-open");
    let buttonClose = document.querySelector("#side-menu-close");
    content.style.right = "100vw";
    headernav.style.right = "0vw";
    buttonOpen.style.display = "none";
    buttonClose.style.display = "block";
  };

  const handleCloseClick = () => {
    let content = document.querySelector("#content-wrapper");
    let headernav = document.querySelector("#side-menu-links");
    let buttonOpen = document.querySelector("#side-menu-open");
    let buttonClose = document.querySelector("#side-menu-close");
    content.style.right = "0";
    headernav.style.right = "-100vw";
    buttonOpen.style.display = "block";
    buttonClose.style.display = "none";
  };

  const handleButtonNClick = (button) => {
    localStorage.setItem("location", "n");
    setLocation("n");
    setActiveButton(button);
    handleCloseClick();
  };

  const handleButtonSClick = (button) => {
    localStorage.setItem("location", "s");
    setLocation("s");
    setActiveButton(button);
    handleCloseClick();
  };

  useEffect(() => {
    btnOpen.current.addEventListener("click", handleOpenClick);
    btnClose.current.addEventListener("click", handleCloseClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      btnOpen.current.removeEventListener("click", handleCloseClick);
      btnClose.current.removeEventListener("click", handleOpenClick);
    };
  }, []);

  return (
    <>
      <div className='side-menu header'>
        <nav className='nav'>
          <div id='side-menu-toggle'>
            <div ref={btnOpen} id='side-menu-open'>
              &#9776;
            </div>
            <div ref={btnClose} id='side-menu-close'>
              &#x2715;
            </div>
          </div>
          <div id='side-menu-links' className='header-nav'>
            <ul className='nav-links'>
              <div className='location-selector'>
                <div
                  className={
                    activeButton === "buttonN"
                      ? "location-button-active"
                      : "location-button"
                  }
                  onClick={() => handleButtonNClick("buttonN")}
                >
                  N
                </div>
                <div
                  className={
                    activeButton === "buttonS"
                      ? "location-button-active"
                      : "location-button"
                  }
                  onClick={() => handleButtonSClick("buttonS")}
                >
                  S
                </div>
              </div>
              <NavLink to='/' onClick={handleCloseClick}>
                Now{" "}
              </NavLink>
              <NavLink to='/plants' onClick={handleCloseClick}>
                Plants{" "}
              </NavLink>
              <NavLink to='/recipes' onClick={handleCloseClick}>
                Recipes{" "}
              </NavLink>
              <NavLink to='/conditions' onClick={handleCloseClick}>
                Effects{" "}
              </NavLink>
              <NavLink to='/about' onClick={handleCloseClick}>
                About{" "}
              </NavLink>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default SideMenu;
