const CultivarPeriods = ({ title, periods }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  return (
    <>
      <h1 className='section-subtitle'>{title}</h1>
      <table className='cultivar-periods'>
        <tbody>
          <tr>
            {Array.from({ length: 12 }, (_, index) => index + 1).map(
              (month) => {
                if (month === currentMonth) {
                  return <td className={"current-month"}>&#9660;</td>;
                } else {
                  return <td></td>;
                }
              }
            )}
          </tr>
          <tr>
            {Array.from({ length: 12 }, (_, index) => index + 1).map(
              (month) => {
                if (periods.includes(month)) {
                  return (
                    <td className={"month active-month"}>
                      {month.toString().padStart(2, "0")}
                    </td>
                  );
                } else {
                  return (
                    <td className={"month"}>
                      {month.toString().padStart(2, "0")}
                    </td>
                  );
                }
              }
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CultivarPeriods;
