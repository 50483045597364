import { useLocationContext } from "../contexts/LocationContext";
import Header from "../components/Header";
import DailyPlant from "../components/DailyPlant";
import DailyCultivar from "../components/DailyCultivar";
import DailyEffect from "../components/DailyEffect";
import DailyRecipe from "../components/DailyRecipe";
import PlantsHarvesting from "../components/PlantsHarvesting";
import PlantsBlossoming from "../components/PlantsBlossoming";
import PlantsPlanting from "../components/PlantsPlanting";
import PlantsSowInside from "../components/PlantsSowInside";
import PlantsSowOutside from "../components/PlantsSowOutside";

const NowPage = () => {
  const { location } = useLocationContext();

  return (
    <div className='main-container grid_2-14'>
      <Header path='/' title='NOW' loc={true} />
      <div className='content-container'>
        <div className={"hero-text green"}>
          Our planet is home to a breathtaking variety of different species,
          each with its own unique character. Today in the{" "}
          {location === "n" ? "northern" : "southern"} hemisphere the following
          specie is active:
        </div>
        <DailyPlant />
        <DailyCultivar />
        <div className={"hero-text green"}>
          Every specie has a peculiar series of potential effects on its
          surroundings. And behind each one of these effects are infinite
          possibilities to be discovered. Between the plants active today in the{" "}
          {location === "n" ? "northern" : "southern"} hemisphere the following
          effect is present:
        </div>
        <DailyEffect />
        <div className={"hero-text green"}>
          Plants have multiple ways of expressing themselves. They have a
          spatial presence, we use them to build everything: from our cloths to
          our buildings and gardens. They enrich our lives with their
          breathtaking forms, colours, fragrances, with the rich taste of their
          leaves, fruits or seeds. With the active plants around you the
          following recipe can be cooked:
        </div>
        <DailyRecipe />
        <div className={"hero-text green"}>
          Plants follow their very own rhythm. Just like us they change through
          time. As a result throughout the year different alliances become
          possible.
        </div>
        <div className={"section-label"}>Active plants</div>
        <PlantsBlossoming />
        <div style={{ clear: "both" }}></div>
        <div className={"section-label"}>Active plants</div>
        <PlantsHarvesting />
        <div style={{ clear: "both" }}></div>
        <div className={"hero-text green"}>
          There are periods of activity and inactivity, the right moment for
          sowing, planting, harvesting. All it takes is some sort of
          feedforward. You have to sow today in order to enter into a positive
          alliance tomorrow. Plants can be your host, you can host plants:
          inside, outside, everywhere.
        </div>
        <div className={"section-label"}>Feedforward</div>
        <PlantsPlanting />
        <div style={{ clear: "both" }}></div>
        <div className={"section-label"}>Feedforward</div>
        <PlantsSowOutside />
        <div style={{ clear: "both" }}></div>
        <div className={"section-label"}>Feedforward</div>
        <PlantsSowInside />
      </div>
    </div>
  );
};

export default NowPage;
