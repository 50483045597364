import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import CultivarTaxonomy from "../components/CultivarTaxonomy";
import CultivarPeriods from "../components/CultivarPeriods";
import CultivarProducer from "../components/CultivarProducer";

const CultivarPage = () => {
  const { id } = useParams();
  const [plant, setPlant] = useState();
  const [cultivar, setCultivar] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/cultivars/";
  const initialUrl = baseUrl + id;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getCultivarData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();

      setCultivar(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCultivarData();
  }, [apiUrl]);

  return (
    <div className='main-container grid_2-14'>
      <Header path={`/plants`} title={`PLANTS`} loc={true} />
      <div className='page-hero-container'>
        {cultivar && <CultivarTaxonomy cultivar={cultivar} />}
      </div>
      <div className='section-separator'></div>
      <h1 className='section-subtitle'>Description</h1>
      <p>{cultivar && cultivar.description}</p>
      {cultivar && cultivar.comment && (
        <>
          <div className='section-separator'></div>
          <h1 className='section-subtitle'>Comment</h1>
          <p>{cultivar.comment}</p>
        </>
      )}
      {cultivar && cultivar.note && (
        <>
          <div className='section-separator'></div>
          <h1 className='section-subtitle'>Note</h1>
          <p>{cultivar.note}</p>
        </>
      )}
      {cultivar && cultivar.peculiarities && (
        <>
          <div className='section-separator'></div>
          <h1 className='section-subtitle'>Pecularities</h1>
          <p>{cultivar.peculiarities}</p>
        </>
      )}
      {cultivar && cultivar.use && (
        <>
          <div className='section-separator'></div>
          <h1 className='section-subtitle'>Use</h1>
          <p>{cultivar.use}</p>
        </>
      )}
      {cultivar && cultivar.sowInsideMonths && (
        <CultivarPeriods
          title={"Sow inside"}
          periods={cultivar.sowInsideMonths}
        />
      )}
      {cultivar && cultivar.sowOutsideMonths && (
        <CultivarPeriods
          title={"Sow outside"}
          periods={cultivar.sowOutsideMonths}
        />
      )}
      {cultivar && cultivar.plantingMonths && (
        <CultivarPeriods title={"Planting"} periods={cultivar.plantingMonths} />
      )}
      {cultivar && cultivar.bloomingMonths && (
        <CultivarPeriods title={"Blooming"} periods={cultivar.bloomingMonths} />
      )}
      {cultivar && cultivar.harvestingMonths && (
        <CultivarPeriods
          title={"Harvesting"}
          periods={cultivar.harvestingMonths}
        />
      )}
      {cultivar && cultivar.storageMonths && (
        <CultivarPeriods title={"Storage"} periods={cultivar.storageMonths} />
      )}
      {cultivar && <CultivarProducer cultivar={cultivar} />}
    </div>
  );
};

export default CultivarPage;
