import { NavLink, useLocation } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";
import "../stylesheets/subheader.css";

const PlantsMenu = ({}) => {
  const location = useLocation();

  const isBlossomingPath = location.pathname === "/plants/blossoming";
  const isharvestingPath = location.pathname === "/plants/harvesting";
  const isPlantingPath = location.pathname === "/plants/planting";
  const isSowInsidePath = location.pathname === "/plants/sow-inside";
  const isSowOutsidePath = location.pathname === "/plants/sow-outside";

  return (
    <div className='subheader-container grid_2-14'>
      {isBlossomingPath ? (
        <div className='active-subheader-link'>BL</div>
      ) : (
        <NavLink to={"/plants/blossoming"} className='subheader-link'>
          BL
        </NavLink>
      )}
      {isharvestingPath ? (
        <div className='active-subheader-link'>HV</div>
      ) : (
        <NavLink to={"/plants/harvesting"} className='subheader-link'>
          HV
        </NavLink>
      )}
      {isPlantingPath ? (
        <div className='active-subheader-link'>PL</div>
      ) : (
        <NavLink to={"/plants/planting"} className='subheader-link'>
          PL
        </NavLink>
      )}
      {isSowInsidePath ? (
        <div className='active-subheader-link'>SI</div>
      ) : (
        <NavLink to={"/plants/sow-inside"} className='subheader-link'>
          SI
        </NavLink>
      )}
      {isSowOutsidePath ? (
        <div className='active-subheader-link'>SO</div>
      ) : (
        <NavLink to={"/plants/sow-outside"} className='subheader-link'>
          SO
        </NavLink>
      )}
    </div>
  );
};

export default PlantsMenu;
