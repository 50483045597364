import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";

const DailyCultivar = () => {
  const { location } = useLocationContext();
  const [cultivar, setCultivar] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/cultivars/daily/";
  const initialUrl = baseUrl + location;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const cultivar = await response.json();
      setCultivar(cultivar);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setApiUrl(initialUrl);
  }, [location, initialUrl]);

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='grid_2-14 section-flex-container'>
      <h1 className='section-label'>Cultivar of the day</h1>
      {cultivar && (
        <NavLink
          className={"section-title green"}
          to={`/plants/${cultivar.subspecieId}/cultivars/${cultivar.cultivarId}`}
        >
          {cultivar.cultivarName}
        </NavLink>
      )}
      {cultivar && (
        <table className='taxonomy'>
          <tbody>
            <tr>
              <td>by</td>
              <td>{cultivar.producerName}</td>
            </tr>
          </tbody>
        </table>
      )}
      <h1 className='specie-title'>
        cultivar of {cultivar && cultivar.subspecieName}
      </h1>
    </div>
  );
};

export default DailyCultivar;
